import React from 'react';

import styled from 'styled-components';

import BuilderLogo from '~/builder/components/header/BuilderLogo';
import { getBrandValue } from '~/shared/utils/brandNameHelper';
import useIsServerSafeMobile from '~/shared/utils/useIsServerSafeMobile';
import { Logo } from '~/techstyle-shared/react-builder-models';

const LogoWrapper = styled.div`
  ${({ theme }) => theme.builderHeader.fallbackHeader.wrapper}
`;
const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 1;
`;

const FallbackHeader = () => {
  const isMobile = useIsServerSafeMobile();

  const fableticsType = isMobile
    ? Logo.TYPES.DEFAULT_FULL_LOGO_FL
    : Logo.TYPES.DEFAULT_MARK_LOGO_FL;
  const sxfType = isMobile
    ? Logo.TYPES.DEFAULT_FULL_LOGO_SXF
    : Logo.TYPES.DEFAULT_MARK_LOGO_SXF;

  const type = getBrandValue({
    savage: sxfType,
    yitty: Logo.TYPES.DEFAULT_FULL_LOGO_YT,
    default: fableticsType,
  });

  return (
    <StickyWrapper>
      <LogoWrapper>
        <BuilderLogo type={type} />
      </LogoWrapper>
    </StickyWrapper>
  );
};

export default FallbackHeader;

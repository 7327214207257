import React from 'react';

import PropTypes from 'prop-types';

import { Logo } from '~/techstyle-shared/react-builder-models';
import {
  FableticsLogo,
  YittyLogo,
  FableticsMarkLogo,
  SavageLogo,
  SavageMarkLogo,
} from '~/techstyle-shared/react-components';

const PreloadedLogo = ({ type }) => {
  switch (type) {
    case Logo.TYPES.DEFAULT_MARK_LOGO_FL:
      return <FableticsMarkLogo width={41} />;
    case Logo.TYPES.DEFAULT_FULL_LOGO_FL:
      return <FableticsLogo width={135} />;
    case Logo.TYPES.DEFAULT_FULL_LOGO_YT:
      return <YittyLogo width={52} />;
    case Logo.TYPES.DEFAULT_MARK_LOGO_SXF:
      return <SavageMarkLogo width={41} />;
    case Logo.TYPES.DEFAULT_FULL_LOGO_SXF:
      return <SavageLogo width={135} />;
    default:
      return null;
  }
};

PreloadedLogo.propTypes = {
  type: PropTypes.string,
};

export default PreloadedLogo;
